import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { Grid, Container } from '@material-ui/core/';
import PromoItem from '../components/promo-item/';
import ContentList from '../components/content-list';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../themes/main-colors';
import SlugLink from '../components/slug-link/';
import { graphql } from 'gatsby';
import BreadCrumb from '../components/pge-breadcrumb';
import {
  getBodyComponent,
  getButtonVariable,
  getContentItems,
  getStatusAlertComponent,
} from '../util/contentful-render-utils';
import { LanguageContext } from '../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      paddingBottom: 200,
    },
    promo: {
      '& .MuiGrid-spacing-xs-3': {
        width: '100%',
        margin: 0,
        padding: theme.spacing(0),
        '& .MuiGrid-item': {
          padding: theme.spacing(0, 2.5, 2.5, 0),
        },
      },
    },
    related: {
      backgroundColor: colors.lightBlue1,
      height: '100%',
      borderRadius: theme.spacing(0.625),
      padding: theme.spacing(1.25, 3.75),
      '& > p': {
        margin: theme.spacing(0),
      },
      '& ul > li > p.pge-contentful-body > a': {
        color: colors.sparkBlue,
      },
    },
    list: {
      backgroundColor: colors.lightGray2,
    },
    listItems: {
      padding: theme.spacing(5, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
      },
    },
    newsDate: {
      fontSize: theme.spacing(2),
      color: colors.noirBlur,
    },
    newsDesc: {
      fontSize: theme.spacing(3),
      color: colors.sparkBlue,
      marginTop: 0,
      '& > a': {
        textDecoration: 'none',
      },
    },
    articleLink: {
      '& > a': {
        textDecoration: 'none',
      },
    },
    promoVideoLink: {
      '& > a': {
        textDecoration: 'none',
      },
    },
  }),
);

export const NewsLandingTemplate = ({ pageContext, data }: any) => {
  const { language } = useContext(LanguageContext);
  const page = pageContext[language || 'en'];
  const classes = useStyles();

  // ** Start temporary rollback of localization for public pages **
  const newsLandingPageData = data.allContentfulPageNewsLanding?.nodes[0];
  // const newsLandingPageData =
  //   data.allContentfulPageNewsLanding?.nodes.find(
  //     (node: any) => node.node_locale === language,
  //   ) || data.allContentfulPageNewsLanding?.nodes[0];
  // ** End temporary rollback of localization for public pages **

  //Extracting Data from News Entries.
  const newsEntries = [];
  const entriesData: any = newsLandingPageData?.newsEntries;

  //Method to format data for press release design
  const formatNewsRelease = (date: any, desc: any, slug: any) => {
    const formattedValue: any = (
      <div className={classes.newsDate}>
        {date}
        <p className={classes.newsDesc}>
          <SlugLink slug={slug} linkText={desc} />
        </p>
      </div>
    );
    return formattedValue;
  };

  //Method to format data for Article Item
  const formatArticleRelease = (header: any, slug: any) => {
    const formattedValue: any = (
      <div className={classes.articleLink}>
        <SlugLink slug={slug} linkText={header} />
      </div>
    );
    return formattedValue;
  };

  if (entriesData && entriesData.length > 0) {
    for (const compObj of entriesData) {
      if (compObj.hasOwnProperty('__typename')) {
        if (compObj['__typename'] === 'ContentfulModulePromoVideoItem') {
          newsEntries.push({
            header: compObj['links'] ? (
              <div className={classes.promoVideoLink}>
                <SlugLink
                  slug={compObj['links']['slug']}
                  linkText={compObj['header']}
                />
              </div>
            ) : (
              compObj['header']
            ),
            description: compObj?.data ? getBodyComponent(compObj.data) : '',
            mediaType: 'video',
            mediaURL: compObj['url']['url'],
          });
        }
        if (compObj['__typename'] === 'ContentfulPageNewsRelease') {
          newsEntries.push({
            description: formatNewsRelease(
              compObj['date'],
              compObj['description'],
              compObj['slug'],
            ),
            mediaType: 'image',
            mediaURL: compObj.url ? compObj['url'].file.url : null,
          });
        }
        if (compObj['__typename'] === 'ContentfulPageArticle') {
          newsEntries.push({
            header: formatArticleRelease(compObj['header'], compObj['slug']),
            description: compObj['description'],
            mediaType: 'image',
            mediaURL: compObj.url ? compObj['url'].file.url : null,
          });
        }
      }
    }
  }

  const newsEntriesLarge: any = newsEntries.splice(0, 1)[0];

  //Method to create design for Promo Items
  const getnewsEntriesObject = (
    item: any,
    isLarge: boolean = false,
    index: number,
  ) => {
    const mdval = isLarge ? 8 : 4;
    return (
      <Grid
        item
        xs={12}
        md={mdval}
        key={index}
        className={'ContentfulModulePromoItem'}
      >
        <PromoItem
          layoutType="vertical"
          mediaURL={item.mediaURL}
          mediaType={item.mediaType}
          header={item?.header}
          description={item.description}
          imageLink={item?.imageLink}
        />
      </Grid>
    );
  };

  //Structuring data for Quick Links Content Block.
  const quickLinksContentBlock = newsLandingPageData?.quickLinksContentBlock
    ? getBodyComponent(newsLandingPageData?.quickLinksContentBlock)
    : '';

  //Extracting Data from Recent Releases Entries.
  const recentReleases: any = newsLandingPageData.recentReleases;

  const contentListHeader = recentReleases
    ? recentReleases.contentListHeader
    : '';
  const contentItems = recentReleases
    ? getContentItems(recentReleases.contentItems)
    : '';

  const btnProps = getButtonVariable(recentReleases?.button, {
    slug: '/news-archive',
  });

  //Structuring data for Media Resources Content Block.
  const mediaResourcesContentBlock = newsLandingPageData?.mediaResourcesContentBlock
    ? getBodyComponent(newsLandingPageData?.mediaResourcesContentBlock)
    : '';

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>
          {newsLandingPageData?.browserTitle || newsLandingPageData?.pageTitle}
        </title>
        {newsLandingPageData?.metaDescriptionSeo && (
          <meta
            name="description"
            content={newsLandingPageData.metaDescriptionSeo}
          />
        )}
        {newsLandingPageData?.excludeFromGoogleSearch && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <div className={classes.main}>
        <Container>
          <BreadCrumb path={page?.breadcrumb} />
        </Container>
        {getStatusAlertComponent(newsLandingPageData?.statusAlert)}
        <Container className={classes.promo}>
          {newsLandingPageData?.pageTitle && (
            <h1 className="pge-contenful-H1">
              {newsLandingPageData?.pageTitle}
            </h1>
          )}
          {entriesData && (
            <Grid container spacing={3} justify="flex-start">
              {getnewsEntriesObject(newsEntriesLarge, true, 0)}
              <Grid item xs={12} md={4}>
                <div className={`${classes.related} ContentfulModuleRelated`}>
                  {quickLinksContentBlock}
                </div>
              </Grid>
            </Grid>
          )}
          {entriesData && (
            <Grid container spacing={3} justify="flex-start">
              {newsEntries.map((item: any, index: number) =>
                getnewsEntriesObject(item, false, index),
              )}
            </Grid>
          )}
        </Container>
        {recentReleases && (
          <div className={classes.list}>
            <Container className={classes.listItems}>
              <ContentList
                rows={contentItems}
                tableHeader={[]}
                heading={contentListHeader}
                button={btnProps}
                columnBackground="LightBlue"
                bordered={true}
              />
            </Container>
          </div>
        )}
        {mediaResourcesContentBlock && (
          <Container>{mediaResourcesContentBlock}</Container>
        )}
      </div>
    </>
  );
};

export default NewsLandingTemplate;

export const query = graphql`
  query PageNewsLanding($slug: String!) {
    allContentfulPageNewsLanding(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        contentful_id
        entryName
        shortMenuTitle
        slug
        pageTitle
        browserTitle
        metaDescriptionSeo
        excludeFromGoogleSearch
        excludeFromXmlSiteMap
        statusAlert {
          ...ModuleStatusAlert
        }
        newsEntries {
          ... on ContentfulModulePromoVideoItem {
            __typename
            id: id
            header: heading
            data: description {
              raw
            }
            links: promoLink {
              ... on ContentfulPageArticle {
                __typename
                slug
              }
              ... on ContentfulPageBasic {
                __typename
                slug
              }
            }
            url: videowrappedImage {
              url
            }
          }
          ... on ContentfulPageNewsRelease {
            __typename
            id: id
            date: releaseDate(formatString: "MMM DD, YYYY")
            description: releaseTitle
            slug: slug
            url: releaseImage {
              file {
                url
              }
            }
          }
          ... on ContentfulPageArticle {
            __typename
            id: id
            header: articleTitle
            description: articleSubtitle
            slug: slug
            url: articleImage {
              file {
                url
              }
            }
          }
        }
        recentReleases {
          contentListHeader
          contentItems {
            ... on ContentfulPageNewsRelease {
              id: id
              title: releaseTitle
              date: releaseDate(formatString: "MMM DD, YYYY")
              slug: slug
              description: metaDescriptionSeo
            }
            ... on ContentfulPageArticle {
              id: id
              title: articleTitle
              slug: slug
              date: updatedAt(formatString: "MMM DD, YYYY")
              descriptionTextNode: metaDescriptionSeo {
                description: metaDescriptionSeo
              }
            }
            ... on ContentfulPageBasic {
              id: id
              title: pageTitle
              slug: slug
              date: updatedAt(formatString: "MMM DD, YYYY")
              description: metaDescriptionSeo
            }
            ... on ContentfulPagePromotional {
              title: pageTitle
              subtitle: pageShortDescription
              slug
              date: updatedAt(formatString: "MMM DD, YYYY")
              description: metaDescriptionSeo
            }
            ... on ContentfulPageList {
              title: pageTitle
              subtitle: pageShortDescription
              slug
              date: updatedAt(formatString: "MMM DD, YYYY")
              description: metaDescriptionSeo
            }
          }
          button {
            buttonType
            buttonText
            buttonIcon {
              file {
                contentType
                fileName
                url
              }
            }
          }
          buttonLink {
            ...pageNewsReleaseLink
          }
        }
        mediaResourcesContentBlock {
          raw
          references {
            contentful_id
            __typename
            ...pageArticleLink
            ...pageBasicLink
            ...pageHomeLink
            ...pageListLink
            ...pageNewsLandingLink
            ...pageNewsReleaseLink
            ...ModuleOverlay
            ...pagePromotionalLink
            ...wrapperExternalLink
          }
        }
        quickLinksContentBlock {
          raw
          references {
            contentful_id
            __typename
            ...pageArticleLink
            ...pageBasicLink
            ...pageListLink
            ...pageNewsLandingLink
            ...pageNewsReleaseLink
            ...ModuleOverlay
            ...pagePromotionalLink
            ...wrapperExternalLink
          }
        }
      }
    }
  }
`;
